import { playMissedSound, playNewPointSound } from '@/plugins/sound'
import { PlayerId, Log, Player } from '@/types'
import Vue from 'vue'

const getRandomNumber = () => Math.floor(Math.random() * 1000000)

export const createState = () =>
  Vue.observable({
    config: {
      winScore: 50,
      fallbackScore: 25,
      missesLimit: 3,
    },
    isSettingScore: false,
    players: [
      { id: getRandomNumber(), name: 'Riri' },
      { id: getRandomNumber(), name: 'Fifi' },
      {
        id: getRandomNumber(),
        name: 'Loulou',
      },
    ] as Player[],
    logs: [] as Log[],
    getLastLog() {
      return this.logs[this.logs.length - 1] || null
    },
    getPlayer(playerId: PlayerId): Player {
      return this.players.find((p) => p.id === playerId) as Player
    },
    setPlayerName(playerId: PlayerId, name: string) {
      this.players = this.players.map((p) =>
        p.id === playerId ? { ...p, name } : p,
      )
    },
    addPlayer(name: string) {
      this.players = [...this.players, { id: getRandomNumber(), name }]
    },
    deletePlayer(playerId: PlayerId) {
      this.players = this.players.filter((p) => p.id !== playerId)
      this.logs = this.logs.filter((l) => l.playerId !== playerId)
    },
    resetAll() {
      this.logs = []
      this.players = this.players.map((p) => ({
        ...p,
        finalPosition: undefined,
      }))
    },
    getCurrentPlayer() {
      const lastPlayerId = this.getLastLog()?.playerId
      const nextPlayerId = this.getNextPlayerId(lastPlayerId)
      return this.getPlayer(nextPlayerId)
    },
    getCurrentPlayerName() {
      return this.getCurrentPlayer()?.name || ''
    },
    getPlayerLastLog(playerId: PlayerId) {
      const isPlayer = (log: Log) => log.playerId === playerId

      return this.logs.filter(isPlayer).pop()
    },
    getPlayerPoints(playerId: PlayerId) {
      return this.getPlayerLastLog(playerId)?.currentScore || 0
    },
    getPlayerMisses(playerId: PlayerId) {
      return this.getPlayerLastLog(playerId)?.currentMisses || 0
    },
    async addCurrentPlayerLog(newPoints: number) {
      const playerId = this.getCurrentPlayer().id

      const newScore = this.getPlayerPoints(playerId) + newPoints
      const isOverWinScore = newScore > this.config.winScore
      const hasWon = newScore === this.config.winScore
      const isMissed = newPoints === 0

      const currentScore = isOverWinScore ? this.config.fallbackScore : newScore
      const currentMisses = isMissed ? this.getPlayerMisses(playerId) + 1 : 0
      const hasLost = currentMisses >= this.config.missesLimit

      // use a watcher for side effects, will be triggered when logs change even for push events
      if (isMissed || isOverWinScore) {
        await playMissedSound()
        navigator?.vibrate([100, 30, 100, 30, 100, 30, 100, 30])
      } else if (hasWon) {
        navigator?.vibrate([500])
        // play winning sound
      } else {
        navigator?.vibrate([500])
        await playNewPointSound()
      }

      this.logs.push({
        logId: (this.getLastLog()?.logId || 0) + 1,
        playerId,
        points: newPoints,
        currentScore,
        currentMisses,
        isOverWinScore,
      })

      if (hasWon) {
        this.players = this.players.map((p) =>
          p.id === playerId ? { ...p, finalPosition: 'winner' } : p,
        )
      } else if (hasLost) {
        this.players = this.players.map((p) =>
          p.id === playerId ? { ...p, finalPosition: 'loser' } : p,
        )
      }
    },
    getNextPlayerId(playerId: PlayerId | undefined): PlayerId {
      if (playerId === undefined) return this.players[0].id

      const playerIndex = this.players.findIndex((p) => p.id === playerId)
      const nextPlayerIndex = (playerIndex + 1) % this.players.length
      const nextPlayer = this.players[nextPlayerIndex]

      return nextPlayer.finalPosition
        ? this.getNextPlayerId(nextPlayer.id)
        : nextPlayer.id
    },
    hasMostPoints(playerId: PlayerId) {
      const playerPoints = this.getPlayerPoints(playerId)

      return this.players
        .filter((p) => p.id !== playerId)
        .map((p) => this.getPlayerPoints(p.id))
        .every((opponentPoints) => opponentPoints < playerPoints)
    },
    isCurrentPlayer(player: Player) {
      return this.getCurrentPlayer()?.id === player.id
    },
    getRemainingPoints(playerId: PlayerId) {
      return this.config.winScore - this.getPlayerPoints(playerId)
    },
    hasLost(playerId: PlayerId) {
      return this.getPlayerMisses(playerId) === this.config.missesLimit
    },
  })

export type State = ReturnType<typeof createState>
