import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import { createState } from '@/store'

Vue.config.productionTip = false

Vue.prototype.$state = createState()

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
