
import Vue, { PropType } from 'vue'
import Scoreboard from './Scoreboard.vue'
import 'magic.css/dist/magic.min.css'
import { Log, Player } from '../types'
import { ANIMATION_DURATION } from '@/plugins/constants'
import 'animate.css'

export default Vue.extend({
  name: 'TeamLine',
  components: {
    Scoreboard,
  },
  props: {
    player: {
      type: Object as PropType<Player>,
      required: true,
    },
  },
  data() {
    return {
      newPointMagicAnimation: '',
      showNewPoints: false,
      isCurrentPlayer: false,
      // debounced player points, to let the animation appear on the screen
      playerPoints: 0,
    }
  },
  created() {
    this.playerPoints = this._playerPoints
    this.isCurrentPlayer = this._isCurrentPlayer
  },
  computed: {
    _isCurrentPlayer(): boolean {
      return this.$state.isCurrentPlayer(this.player)
    },
    // reactive player points
    _playerPoints(): number {
      return this.$state.getPlayerPoints(this.player.id)
    },
    isWinner(): boolean {
      return this.player.finalPosition === 'winner'
    },
    hasLost(): boolean {
      return this.player.finalPosition === 'loser'
    },
    lastPlayerLogId(): number | undefined {
      return this.$state.getPlayerLastLog(this.player.id)?.logId
    },
    lastPlayerLog(): Log | null {
      return this.$state.getPlayerLastLog(this.player.id)
    },
    progressPercentage(): number {
      return (
        (this.$state.getPlayerPoints(this.player.id) /
          this.$state.config.winScore) *
        100
      )
    },
    playerRemainingPoints(): number {
      return this.$state.getRemainingPoints(this.player.id)
    },
    showRemainingPoints(): boolean {
      return (
        this.isCurrentPlayer &&
        this.playerRemainingPoints <= 12 &&
        !this.showNewPoints
      )
    },
    newPointsClass(): string {
      const color =
        !this.lastPlayerLog?.points || this.lastPlayerLog?.isOverWinScore
          ? 'error'
          : 'yellow'

      return color + ' ' + this.newPointMagicAnimation
    },
  },
  watch: {
    _isCurrentPlayer(isCurrentPlayer) {
      setTimeout(() => {
        this.isCurrentPlayer = isCurrentPlayer
      }, ANIMATION_DURATION)
    },
    _playerPoints(playerPoints) {
      setTimeout(() => {
        this.playerPoints = playerPoints
      }, ANIMATION_DURATION / 4)
    },
    async lastPlayerLogId() {
      this.showNewPoints = true

      this.newPointMagicAnimation = 'animate__animated animate__bounce '

      setTimeout(async () => {
        this.newPointMagicAnimation = 'magictime vanishOut'
        setTimeout(() => {
          this.showNewPoints = false
        }, ANIMATION_DURATION)
      }, ANIMATION_DURATION)
    },
  },
})
