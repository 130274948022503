
import Vue from 'vue'
import TeamLine from './components/TeamLine.vue'
import ModalPoints from './components/ModalPoints.vue'
import { AudioState } from '@/plugins/sound'
// import { ANIMATION_DURATION } from '@/plugins/constants'
import WinnerAnimation from './components/WinnerAnimation.vue'
import EditPlayers from './components/EditPlayers.vue'

export default Vue.extend({
  name: 'App',
  components: {
    TeamLine,
    ModalPoints,
    EditPlayers,
    // WinnerAnimation,
  },
  data() {
    return {
      showModal: false,
      showFab: true,
      editActive: false,
      drawer: false,
    }
  },
  computed: {
    isAudioActive: {
      get(): boolean {
        return AudioState.active
      },
      set(active: boolean) {
        AudioState.active = !!active
      },
    },
  },
  methods: {
    reset() {
      this.$state.resetAll()
      this.drawer = false
    },
    openRules() {
      window.open('https://en.wikipedia.org/wiki/Molkky', '_blank')
      this.drawer = false
    },
    async addPoints(points: number) {
      this.showFab = false
      this.showModal = false

      this.$state.addCurrentPlayerLog(points)

      // to let the animation happens before going to next player
      // setTimeout(() => {
      this.showFab = true
      // }, ANIMATION_DURATION)
    },
  },
})
