import { reactive } from 'vue'

export const AudioState = reactive({
  active: false,
})

const NewPointAudio = new Audio(require('@/assets/new-point-sound.mp3'))
const MissedPointAudio = new Audio(require('@/assets/motus-boule-noire.mp3'))

export const playNewPointSound = async () => {
  if (!AudioState.active) return

  return NewPointAudio.play()
}

export const playMissedSound = async () => {
  if (!AudioState.active) return

  return MissedPointAudio.play()
}
