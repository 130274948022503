
import Tick from '@pqina/flip'
import '@pqina/flip/dist/flip.min.css'
import Vue, { defineComponent } from 'vue'

type Flipboard = {
  set: (newValue: any) => any
  destroy: () => any
}

const createFlipboard = (el, value) => {
  const board = Tick.DOM.create(el, { value })

  return {
    set: (newValue) => (board.value = newValue),
    destroy: () => Tick.DOM.destroy(el),
  }
}

export default defineComponent({
  name: 'Scoreboard',
  props: {
    value: { type: Number, required: true },
    active: { type: Boolean, default: false },
  },
  data: () => ({ board: null as null | Flipboard }),
  computed: {
    twoDigitsValue(): string {
      return `${this.value < 10 ? '0' : ''}${this.value}`
    },
  },
  watch: {
    twoDigitsValue(newValue) {
      this.board?.set(newValue)
    },
  },
  mounted() {
    this.board = createFlipboard(this.$refs.tick, this.twoDigitsValue)
  },
  destroyed() {
    this.board?.destroy()
  },
})
