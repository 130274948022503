
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'EditPlayers',
  components: {},
  methods: {
    reset() {
      this.$state.resetAll()
      this.$emit('close')
    },
  },
})
