
import Vue, { PropType } from 'vue'
import BtnPoints from './BtnPoints.vue'

export default Vue.extend({
  name: 'ModalPoints',
  components: {
    BtnPoints,
  },
  props: {
    team: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function as PropType<(points: number) => void>,
      required: true,
    },
  },
})
