
import Vue, { PropType } from 'vue'
import 'magic.css/dist/magic.min.css'

export default Vue.extend({
  name: 'BtnPoints',
  props: {
    points: {
      type: Number,
      required: true,
    },
    onClick: {
      type: Function as PropType<(points: number) => void>,
      required: true,
    },
  },
})
